$primary-color: #091C99;

:root {
	--primary-color: #091C99;
	--secondary-color: #0A1F80;
	--accent-color: #2196F3;
	--text-color: #333;
}

/* css */
body {
	color: #363636;

	.wrapper {
		padding-top: 3.6rem;

		header {

			/* navigation */
			.navbar {
				padding: 0;
			}

			.navbar .nav-item {
				text-align: center;
			}

			.navbar-light .navbar-nav .nav-link,
			.navbar-light .navbar-nav .active>.nav-link {
				font-size: 17px;
				padding: 13px 20px;
				position: relative;
			}

			.navbar-light .navbar-nav .nav-link {
				-webkit-transition: .3s;
				-o-transition: .3s;
				transition: .3s;
			}

			.navbar-light .navbar-nav .nav-link:before {
				display: block;
				content: ' ';
				position: absolute;
				height: 3px;
				top: 0;
				background-color: $primary-color;

				-webkit-transition: .3s;
				-o-transition: .3s;
				transition: .3s;
				-webkit-transition-timing-function: cubic-bezier(.58, .3, .005, 1);
				-o-transition-timing-function: cubic-bezier(.58, .3, .005, 1);
				transition-timing-function: cubic-bezier(.58, .3, .005, 1);

				width: 0;
				left: -10px;
				opacity: 0;
			}

			.navbar-light .navbar-nav .nav-link:hover,
			.navbar-light .navbar-nav .nav-item.active .nav-link {
				color: $primary-color;
			}

			.navbar-light .navbar-nav .nav-link:hover:before,
			.navbar-light .navbar-nav .nav-item.active .nav-link:before {
				width: 100%;
				left: 0;
				opacity: 1;
			}
		}

		article.post {
			padding-top: 60px;

			.post-content {
				color: black;
				line-height: 1.8rem;
				font-size: 1.2rem;

				.entry-header {
					line-height: 1.9rem;
					font-size: 1.3rem;
					padding-bottom: 19px;
				}

			}
		}

		article.page {

			section#products {
				.card {
					.card-img-top {
						max-height: 235px;
					}

					h3 {
						min-height: 50px;
					}

					p {
						color: black;
						text-decoration: none;
					}

					.text-end {
						margin-top: 1em;
					}
				}
			}

			section.hero-section {
				padding-top: 60px;
				padding-bottom: 30px;

				.carousel-wrapper {
					position: relative;
					padding: 0 50px;
					/* Make space for outside controls */
				}

				/* Position controls outside */
				.carousel-control-prev {
					left: -50px;
					width: 40px;
					background-color: #f8f9fa;
					border-radius: 50%;
					opacity: 1;
					height: 40px;
					top: 45%;
				}

				.carousel-control-next {
					right: -50px;
					width: 40px;
					background-color: #f8f9fa;
					border-radius: 50%;
					opacity: 1;
					height: 40px;
					top: 45%;
				}

				/* Style control icons */
				.carousel-control-prev-icon {
					background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
				}

				.carousel-control-next-icon {
					background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
				}

				/* Style image caption */
				.carousel-caption-below {
					text-align: center;
					color: #333;
					padding: 10px 0;
					margin-bottom: 10px;
				}

				/* Reposition indicators */
				.carousel-indicators {
					position: relative;
					margin-top: 10px;
					margin-bottom: 0;
				}

				.carousel-indicators [data-bs-target] {
					background-color: #FFF;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin: 0 5px;
				}
			}

		}
	}

	&.scrolled {
		header img.logo {
			height: 45px;
		}
	}

	main.index {

		section#services {
			.card {
				.card-img-top {
					max-height: 235px;
				}

				h3 {
					min-height: 50px;
				}

				p {
					color: black;
					text-decoration: none;
				}

				.text-end {
					margin-top: 1em;
				}
			}
		}

		/* section-arrow */
		.section-arrow {
			position: absolute;
			z-index: 2;

			img {
				height: 20px;
			}

			.section-arrow-react {
				position: relative;
				cursor: pointer;
				padding: 20px;
			}

			.section-arrow-react:hover {
				-webkit-animation: arrows 1s ease-in;
				-moz-animation: arrows 1s ease-in;
				-o-animation: arrows 1s ease-in;
				animation: arrows 1s ease-in;
			}
		}



		.section-1 .section-arrow {
			right: 12px;
			top: 380px;
		}

		.section-3 .section-arrow {
			right: 12px;
			top: 300px;
		}

		.section-4 .section-arrow {
			right: 12px;
			top: 40px;
		}

		/* section 1*/
		.section-1 {
			position: relative;
			background-color: #f2f2f2;
			background-image: url('/assets/img/neahtid-home.png');
			background-position: bottom center;
			background-repeat: no-repeat;
			min-height: 560px;

			text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
			color: #54595f;

			.container {
				padding: 90px 90px;
			}

			h2 {
				max-width: 45vw;
			}

			h3 {
				margin-top: 100px;
				font-size: 20px;
				font-weight: 400;
				max-width: 50vw;
			}
		}


		/* section 2 */
		.section-2 {
			background-color: #F4F4F4;
			text-align: center;
			padding: 50px;

			p {
				margin-top: 12px;
			}
		}


		/* section 3 */
		.section-3 {
			position: relative;
			background-color: #FAFAFA;

			img {
				max-width: 100%;
			}

			.infoblock {
				padding: 60px 20px;
			}
		}



		/* section 4 */
		.section-4 {
			position: relative;
			background-color: #F4F4F4;
			text-align: center;
			padding: 50px;

			.badges .badge-inner {
				width: 80%;
				margin: 0 auto;
			}

			.badges .badge h2 {
				background-color: #E2E2E2;
				padding: 5% 0;
				font-size: 1.6em;
			}

			.badges .badge img {
				width: 100%;
			}
		}

		/* section 5 */
		.section-5 {
			background-color: #FAFAFA;
			text-align: center;
			padding: 50px;

			a {
				font-size: 14px;
			}
		}
	}

	.product-icon {
		width: 64px;
		height: 64px;
		background-color: #f8f9fa;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
	}

	.btn-product-demo {
		background-color: #6610f2;
		color: white;
	}

	.btn-product-demo:hover {
		background-color: #520dc2;
		color: white;
	}

	.info-page {

		/* Futuristic animations */
		@keyframes float {
			0% {
				transform: translateY(0px);
			}

			50% {
				transform: translateY(-20px);
			}

			100% {
				transform: translateY(0px);
			}
		}

		@keyframes pulse {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.05);
			}

			100% {
				transform: scale(1);
			}
		}

		@keyframes gradient {
			0% {
				background-position: 0% 50%;
			}

			50% {
				background-position: 100% 50%;
			}

			100% {
				background-position: 0% 50%;
			}
		}

		header {
			margin-top: 2.3rem;
			background: linear-gradient(-45deg, var(--primary-color), var(--secondary-color), var(--accent-color));
			background-size: 400% 400%;
			animation: gradient 15s ease infinite;
			position: relative;
			overflow: hidden;
			color: white;
		}

		/* Animated elements */
		.tech-circle {
			position: absolute;
			border-radius: 50%;
			border: 2px solid rgba(255, 255, 255, 0.1);
			animation: float 6s ease-in-out infinite;
		}
	}

	footer {
		border-top: 3px solid $primary-color;

		/* Base styles */
		.services-menu .list-group-item {
			transition: all 0.2s ease-in-out;
			border-left: 4px solid transparent;
		}

		.services-menu .list-group-item a {
			position: relative;
			min-height: 3.5rem;
		}

		/* Hover state */
		.services-menu .list-group-item:hover,
		.services-menu .list-group-item:active {
			background-color: rgba(var(--bs-primary-rgb), 0.03);
		}

		.services-menu .list-group-item:hover a {
			color: var(--bs-primary) !important;
		}

		/* Active state */
		.services-menu .list-group-item.active {
			background-color: rgba(var(--bs-primary-rgb), 0.05);
			border-left-color: var(--bs-primary);
		}

		.services-menu .list-group-item.active a {
			color: var(--bs-primary) !important;
			font-weight: 500;
		}

		.services-menu .list-group-item.active .menu-arrow {
			color: var(--bs-primary);
		}

		/* Arrow styles */
		.menu-arrow {
			color: #6c757d;
			flex-shrink: 0;
			transition: transform 0.2s ease;
		}

		.services-menu .list-group-item:hover .menu-arrow {
			transform: translateX(3px);
		}

		/* Mobile optimization */
		@media (max-width: 768px) {
			.services-menu .list-group-item {
				border-left-width: 3px;
			}

			.services-menu .list-group-item a {
				padding-left: calc(1rem - 3px);
			}
		}
	}
}

.flex-container {
	display: flex;
	align-items: center;
}

.fa-inactive {
	display: none;
}

.fa-active {
	display: inherit;
}


/* animated-hidden */
.animated-hidden {
	position: relative;
	opacity: 0;
	left: -600px;

	-webkit-transition: 1.5s;
	-o-transition: 1.5s;
	transition: 1.5s;
}

.animated-hidden-right {
	position: relative;
	opacity: 0;
	left: 400px;

	-webkit-transition: 1.5s;
	-o-transition: 1.5s;
	transition: 1.5s;
}

.animated-visible {
	opacity: 1;
	left: 0;
}

/* forms elements and section-6 */

input,
textarea {
	font-size: 15px;
	min-height: 40px;
	padding: 5px 14px;
	width: 100%;
	border: 1px solid #818a91;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	outline: 0 none;
}

input:focus,
textarea:focus {
	box-shadow: 0 1px 1px #818a9199 inset, 0 0 1px #818a9199;
}

input[type="checkbox"],
input[type="radio"],
input.radio,
input.checkbox {
	vertical-align: text-top;
	width: 13px;
	height: 13px;
	padding: 0;
	margin: 0;
	position: relative;
	overflow: hidden;
	top: 2px;
}

fieldset.attend {
	overflow: hidden
}

fieldset.attend div {
	float: left;
	clear: none;
}

fieldset.attend label {
	float: left;
	clear: both;
	display: block;
	padding: 2px 1em 0 0;
}

fieldset.attend input[type=radio],
fieldset.attend input.radio {
	float: left;
	clear: none;
	margin: 2px 0 0 2px;
}


.section-6 button:hover,
.contact-2 button:hover,
.relations-modal button:hover {
	background-color: #525252;
}



/* headers */
.headblock {
	height: 380px;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.headblock h1 {
	color: #fff;
	text-shadow: 0px 0px 10px #000000;
	font-weight: bold;
	font-size: 30px;
}

/* contact us */
.contact-1 {
	background-image: url('../img/neahtid-contact.jpg');
}

.contact-2 {
	background-color: #F4F4F4;
	padding: 50px;
}

.contact-input label {
	margin: 0;
	margin-top: 5px;
}

.contact-input label .required {
	color: red;
}

.contact-badge,
.contact-badge:link,
.contact-badge:visited {
	display: block;
	height: 200px;
	width: 100%;
	margin: 0 auto;
	color: #fff;
	text-decoration: none;
	text-align: center;
	margin-bottom: 20px;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-badge h2 {
	text-shadow: 0px 2px 2px rgba(15, 15, 15, 0.91);
}

.contact-badge.relations {
	background-image: url('../img/neahtid-photo-297755.jpg');
}

.contact-badge.work {
	background-image: url('../img/summer-office-student-work.jpg');
}

/* Our company */

.contact-badge.company-research {
	background-image: url('../img/neahtid-photo-279753.jpg');
}

.contact-badge.company-relations {
	background-image: url('../img/earth-earth-at-night-night-lights-41949.jpeg');
}

.contact-badge.company-work {
	background-image: url('../img/our-company-work-with-us.jpeg');
}

/* relations */
.relations-1 {
	background-image: url('../img/earth-earth-at-night-night-lights-41949.jpeg');
	background-attachment: fixed;
}

.contact-badge.relations-research {
	height: 230px;
	background-image: url('../img/neahtid-photo-279755.jpg');
	margin-top: 20px;
}

.contact-badge.relations-research h2 {
	line-height: 230px;
}

.contact-badge.relations-company {
	height: 230px;
	background-image: url('../img/neahtid-photo-459656.png');
}

.contact-badge.relations-company h2 {
	line-height: 230px;
}

.white-island {
	background-color: #fff;
	padding: 40px;
	color: #54595f;
}

.white-island h1 {
	font-weight: bold;
}

.white-island button {
	width: inherit;
	display: inline-block;
	padding: 18px 40px;
	font-size: 17px;
	background-color: $primary-color;
	color: #fff;
	border-radius: 5px;
	text-decoration: none;
}

.relations-modal {
	margin: 0 auto;
	text-align: center;
	margin-bottom: 50px;
}

/* Menu responsive */
@media only screen and (max-width: 999px) {
	body {
		nav ul.navbar-nav {
			li {
				width: 100%;

				a {
					width: 100%;
				}
			}
		}
	}
}

/* Tablets (768px and below) */
@media only screen and (max-width: 768px) {

	/* Your CSS styles for tablets */
	body {
		background-color: #f9ffff;

		.hero-section {
			padding-top: 90px;
			padding-bottom: 60px;
		}
	}

	div.section-arrow {
		right: 50px;
	}

	.section-1 {
		background-position: -450px 0px;
		margin-bottom: -20px;
	}

	.section-1 .section-arrow {
		top: 450px;
	}

	.section-1 .container {
		padding: 120px 120px 0 20px;
	}

	.section-3 .section-arrow {
		top: 670px;
	}

	.section-4 .section-arrow {
		top: 830px;
	}

	.navbar-brand img {
		height: 74px;
		padding: 5px 10px;
	}

	.navbar-toggler {
		margin-right: 10px;
	}

	.footer .footer-list li {
		list-style: none;
		text-align: center;
	}

	.headblock {
		height: 200px;
	}

	.headblock h1 {
		font-size: 25px;
	}

	h2 {
		font-size: 25px;
	}

	.white-island {
		padding: 20px;
	}

	.white-island h1 {
		font-size: 30px;
	}

	.flex-btn-container a {
		font-size: 15px;
	}

	.contact-2,
	.research-2 {
		padding: 10px;
	}


}

/* Smartphones in Landscape (576px and below) */
@media only screen and (max-width: 576px) {

	/* Your CSS styles for smartphones in landscape */
	body {
		background-color: #fffff9;

		.wrapper {
			article.page {
				section.hero-section {
					padding-top: 60px;
					padding-bottom: 30px;
				}
			}
		}

		footer {
			.footer-links div a {
				display: block;
				padding: 10px 5px;
			}
		}
	}

	.section-1 {
		background-position: -500px center;
	}

	div.section-arrow {
		right: 10px !important;
	}
}


/* Smartphones in Portrait (480px and below) */
@media only screen and (max-width: 480px) {

	/* Your CSS styles for smartphones in portrait */
	body {
		background-color: #fff9ff;
	}


	.section-1 {
		background-position: -700px center;
	}

	.section-1 .container {
		padding: 20px;
	}

	.section-1 h2 {
		max-width: 100%;
	}

	.section-1 h3 {
		margin-top: 50px;
	}

	.section-4,
	.footer,
	.section-5 {
		padding: 11px 9px !important;
	}

	.badges .badge img {

		margin-bottom: 30px;
	}

}